import getConfig from 'next/config'

import type { FeatureToggles } from '@grandvisionhq/state'
import { createFeatureConfigs, createFeatureSet } from '@grandvisionhq/state'

const { publicRuntimeConfig } = getConfig()

const featureSet = createFeatureSet(publicRuntimeConfig.grandvision.features ?? [])
const featureConfigs = createFeatureConfigs(publicRuntimeConfig.grandvision.featureConfigs ?? {})

export const featureToggles: FeatureToggles = {
  ...featureSet,
  featureConfigs,
}
